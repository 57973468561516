import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { instant } from '../../shared/shared.module';
import { Subject } from 'rxjs';
import { AlertType } from 'src/app/shared/alerts/alert-type.enum';
import { AlertService } from 'src/app/shared/alerts/alert.service';
import { DataCleanupService } from 'src/app/shared/data-cleanup/data-cleanup.service';
import { Language, UserGender, UserRole } from 'src/app/types/enums.model';
import { LoginUser, RegisterUser, ResetPasswordUser, TwoFactorAuthUser } from 'src/app/types/user.model';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { RegistrationFailedReason } from 'src/app/types/auth.model';
import { ThemeService } from 'src/app/shared/theme.service';
import { BookingService } from 'src/app/home/booking/booking.service';


const BACKEND_URL = environment.apiUrl;
const isMyBloodDrop = environment.isMyBloodDrop;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authStatusListener = new Subject<boolean>();
  private forgotPasswordStatusListener = new Subject<boolean>();
  private resetPasswordStatusListener = new Subject<boolean>();
  private requestTwoFactorAuthStatusListener = new Subject<boolean>();
  private requestRegisterTwoFactorAuthStatusListener = new Subject<boolean | RegistrationFailedReason>();
  private validateTwoFactorAuthStatusListener = new Subject<boolean>();
  private registerStatusListener = new Subject<boolean>();
  private isAuthenticated = false;
  private authToken: string | undefined;
  private expirationTimer: NodeJS.Timer | undefined;
  private expirationDate: Date | undefined;
  private tokenRefreshInProgress = false;
  private userID: string | undefined;
  private role: UserRole | undefined;
  private firstName: string | undefined;
  private lastName: string | undefined;
  private email: string | undefined;
  private dateOfBirth: Date | undefined;
  private gender: UserGender | undefined;
  private phoneNumber: string | undefined;
  private defaultLanguage: Language | undefined;

  private redirectUrl: string | undefined;
  private temporaryUser: any;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private cleanupService: DataCleanupService,
    private themeService: ThemeService,
    private bookingService: BookingService
  ) {

  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  getForgotPasswordStatusListener() {
    return this.forgotPasswordStatusListener.asObservable();
  }

  getResetPasswordStatusListener() {
    return this.resetPasswordStatusListener.asObservable();
  }

  getIsAuthenticated() {
    return this.isAuthenticated;
  }

  getRequestTwoFactorAuthStatusListener() {
    return this.requestTwoFactorAuthStatusListener.asObservable();
  }

  getRequestRegisterTwoFactorAuthStatusListener() {
    return this.requestRegisterTwoFactorAuthStatusListener.asObservable();
  }

  getValidateTwoFactorAuthStatusListener() {
    return this.validateTwoFactorAuthStatusListener.asObservable();
  }

  getRegisterStatusListener() {
    return this.registerStatusListener.asObservable();
  }

  getAuthToken() {
    // check if token needs to be refreshed
    if (this.authToken && this.expirationDate && !this.tokenRefreshInProgress) {
      const expiresIn = (this.expirationDate.getTime() - (new Date()).getTime()) / 1000;
      if (expiresIn < environment.tokenRefreshThreshold) {
        // asynchronously refresh auth token - until refresh finishes, the old token is used
        this.tokenRefreshInProgress = true;
        this.refreshAuthToken()
          .then(() => {
            this.tokenRefreshInProgress = false;
          })
          .catch(error => {
            this.tokenRefreshInProgress = false;
          });
      }
    }

    return this.authToken;
  }

  getUserID() {
    return this.userID;
  }

  getRole() {
    return this.role;
  }

  getFirstName() {
    return this.firstName;
  }

  getLastName() {
    return this.lastName;
  }

  getEmail() {
    return this.email;
  }

  getDateOfBirth() {
    if (this.dateOfBirth == null) return 
    const dob = new Date(this.dateOfBirth)
    const parsedDate = dob != null ? `${`0000${dob?.getFullYear()}`.slice(-4)}-${`00${dob?.getMonth() + 1}`.slice(-2)}-${`00${dob?.getDate()}`.slice(-2)}` : null;
    return parsedDate;
  }

  getGender() {
    return this.gender;
  }

  getPhoneNumber() {
    return this.phoneNumber;
  }

  getDefaultLanguage() {
    return this.defaultLanguage;
  }

  setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }

  autoLogin() {
    // load auth data from localStorage
    const authData = this.loadAuthData();
    if (!authData) {
      // no auth data found
      return;
    }

    // calculate time until token expires in seconds (hence / 1000)
    const expiresIn = (authData.expirationDate.getTime() - (new Date()).getTime()) / 1000;
    if (expiresIn > 0) {
      // auth data valid, authenticate user
      this.authenticateUser(
        authData.token,
        authData.expirationDate,
        authData.userID,
        authData.role,
        authData.firstName,
        authData.lastName,
        authData.email,
        authData.dateOfBirth,
        authData.gender,
        authData.phoneNumber,
        authData.defaultLanguage
      );
    } else {
      // auth data expired, remove it from localStorage
      this.clearAuthData();
    }
  }

  private refreshAuthToken() {
    return new Promise((resolve, reject) => {
      // call backend api
      this.httpClient.get<{
        token: string,
        exp: number
      }>(BACKEND_URL + '/authenticate/refresh')
        .subscribe(
          response => {
            // convert expiration unix epoch time to JS date
            const expirationDate = new Date(response.exp * 1000);

            // authenticate user with auth information from response
            this.setRefreshedAuthToken(
              response.token,
              expirationDate
            );

            resolve(true);
          },
          error => {
            // token refresh failed
            console.log(error);
            reject(error);
          });
    });
  }

  logout() {
    // clear auth data stored in service
    this.authToken = undefined;
    this.isAuthenticated = false;
    this.userID = undefined;
    this.role = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.email = undefined;
    this.dateOfBirth = undefined;
    this.gender = undefined;
    this.phoneNumber = undefined;
    this.defaultLanguage = undefined;

    // clear auth data store in localStorage
    this.clearAuthData();

    // notify Observers about changed auth state
    this.authStatusListener.next(false);

    // deactivate token expiration timer
    if (this.expirationTimer != null) {
      clearTimeout(this.expirationTimer);
    }

    // cleanup stored data from all services
    this.cleanupService.cleanupAllServices();

    // redirect to login page
    this.router.navigate(['auth']);
  }

  public authenticateUser(
    token: string,
    expirationDate: Date,
    userID: string,
    role: UserRole,
    firstName: string,
    lastName: string,
    email: string,
    dateOfBirth: Date,
    gender: UserGender,
    phoneNumber: string,
    defaultLanguage: Language,
    notifyObservers: boolean = true
  ) {
    // calculate time until expiration (for timer)
    const expiresIn = (expirationDate.getTime() - (new Date()).getTime()) / 1000;
    // check if token is already expired
    if (expiresIn > 0) {
      // store auth info in service
      this.authToken = token;
      this.setExpirationTimer(expirationDate);
      this.isAuthenticated = true;
      this.userID = userID;
      this.role = role;
      this.firstName = firstName;
      this.lastName = lastName;
      this.email = email;
      this.dateOfBirth = dateOfBirth;
      this.gender = gender;
      this.phoneNumber = phoneNumber;
      this.defaultLanguage = defaultLanguage;

      // set language to default language
      this.translateService.use(defaultLanguage);

      // notify Observers about changed auth state
      if (notifyObservers) {
        this.authStatusListener.next(true);
      }

      // store auth data in localStorage
      this.storeAuthData(
        token,
        expirationDate,
        userID,
        role,
        firstName,
        lastName,
        email,
        dateOfBirth,
        gender,
        phoneNumber,
        defaultLanguage
      );
    } else {
      // token already expired -- logout user
      this.logout();
      this.alertService.createAlert(
        AlertType.Danger,
        this.translateService.instant('app.auth.service.sessionExpired'),
        null
      );
    }
  }

  private setRefreshedAuthToken(token: string, expirationDate: Date) {
    // calculate time until expiration (for timer)
    const expiresIn = (expirationDate.getTime() - (new Date()).getTime()) / 1000;
    // check if token is already expired
    if (expiresIn > 0) {
      // store auth info in service
      this.authToken = token;
      this.setExpirationTimer(expirationDate);

      // notify Observers about changed auth state
      this.authStatusListener.next(true);

      // store auth data in localStorage
      this.storeAuthData(
        token,
        expirationDate
      );
    } else {
      // new token already expired -- logout user
      this.logout();
      this.alertService.createAlert(
        AlertType.Danger,
        this.translateService.instant('app.auth.service.sessionExpired'),
        null
      );
    }
  }

  private setExpirationTimer(expirationDate: Date) {
    // calculate time until expiration (for timer)
    const expiresIn = (expirationDate.getTime() - (new Date()).getTime()) / 1000;

    // clear any given timeout (in case of token refresh)
    if (this.expirationTimer != null) {
      clearTimeout(this.expirationTimer);
    }

    // after given duration (parameter given in seconds, hence * 1000),
    // call logout method
    this.expirationTimer = setTimeout(() => {
      this.logout();
      this.alertService.createAlert(
        AlertType.Danger,
        this.translateService.instant('app.auth.service.autoLogout'),
        null
      );
    }, expiresIn * 1000);

    this.expirationDate = expirationDate;
  }

  private storeAuthData(
    token: string,
    expirationDate: Date,
    userID?: string,
    role?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    dateOfBirth?: Date,
    gender?: UserGender,
    phoneNumber?: string,
    defaultLanguage?: Language
  ) {
    localStorage.setItem('token', token);
    localStorage.setItem('exp', expirationDate.toISOString());
    if (userID) {
      localStorage.setItem('userID', userID);
    }
    if (role) {
      localStorage.setItem('role', role);
    }
    if (firstName) {
      localStorage.setItem('firstName', firstName);
    }
    if (lastName) {
      localStorage.setItem('lastName', lastName);
    }
    if (email) {
      localStorage.setItem('email', email);
    }
    if (dateOfBirth) {
      const parsedDate = new Date(dateOfBirth);
      const parsedDoB = `${`0000${parsedDate?.getFullYear()}`.slice(-4)}-${`00${parsedDate?.getMonth() + 1}`.slice(-2)}-${`00${parsedDate?.getDate()}`.slice(-2)}`;
      localStorage.setItem('dateOfBirth', parsedDoB)
    }
    if (gender) {
      localStorage.setItem('gender', gender)
    }
    if (phoneNumber) {
      localStorage.setItem('phoneNumber', phoneNumber)
    }
    if (defaultLanguage) {
      localStorage.setItem('defaultLanguage', defaultLanguage);
    }
  }

  private loadAuthData() {
    // load auth information from localStorage
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('exp');
    const userID = localStorage.getItem('userID');
    const role = localStorage.getItem('role') as UserRole;
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    const email = localStorage.getItem('email');
    const dateOfBirth = localStorage.getItem('dateOfBirth');
    const gender = localStorage.getItem('gender') as UserGender;
    const phoneNumber = localStorage.getItem('phoneNumber');
    const defaultLanguage = localStorage.getItem('defaultLanguage');

    // check if information was found
    if (!token || !expirationDate || !userID || !role || !firstName || !lastName || !email || !dateOfBirth || !gender || !phoneNumber || !defaultLanguage) {
      return undefined;
    }

    // information was found, return auth data
    return {
      token,
      expirationDate: new Date(expirationDate),
      userID,
      role,
      firstName,
      lastName,
      email,
      dateOfBirth: new Date(dateOfBirth),
      gender,
      phoneNumber,
      defaultLanguage: defaultLanguage as Language
    };
  }

  private clearAuthData() {
    // remove auth data from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('exp');
    localStorage.removeItem('userID');
    localStorage.removeItem('role');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('email');
    localStorage.removeItem('dateOfBirth');
    localStorage.removeItem('gender');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('defaultLanguage');
  }


  /**
   * PASSWORD RESET FUNCTIONALITY
   */
  public requestPasswordReset(email: string) {
    // call backend api
    this.httpClient.post(BACKEND_URL + '/authenticate/request-password-reset', { email }, { responseType: 'text' })
      .subscribe(
        _ => {
          this.forgotPasswordStatusListener.next(true);
        },
        error => {
          console.log(error);

          this.alertService.createAlert(
            AlertType.Danger,
            this.translateService.instant('app.auth.service.passwordResetRequestFailed')
          );

          this.forgotPasswordStatusListener.next(false);
        });
  }

  public resetPassword(resetPasswordUser: ResetPasswordUser) {
    // call backend api
    this.httpClient.post(BACKEND_URL + '/authenticate/reset-password', resetPasswordUser, { responseType: 'text' })
      .subscribe(
        _ => {
          this.resetPasswordStatusListener.next(true);

          // show success notification
          this.alertService.createAlert(
            AlertType.Success,
            this.translateService.instant('app.auth.service.passwordResetSuccessful')
          );
        },
        error => {
          console.log(error);

          this.alertService.createAlert(
            AlertType.Danger,
            this.translateService.instant('app.auth.service.passwordResetFailed')
          );

          this.resetPasswordStatusListener.next(false);
        });
  }

  /**
   * TWO FACTOR AUTH FUNCTIONALITY
   */
  public requestTwoFactorAuth(loginUser: LoginUser) {
    this.httpClient.post(BACKEND_URL + '/authenticate/login', loginUser, { responseType: 'text' })
      .subscribe(response => {
        this.requestTwoFactorAuthStatusListener.next(true);
      },
      error => {
        console.log(error);
        this.alertService.createAlert(
          AlertType.Danger,
          this.translateService.instant('app.auth.service.emailOrPasswordInvalid')
        );
        this.requestTwoFactorAuthStatusListener.next(false);
      });
  }

  public validateTwoFactorAuth(twoFactorAuthUser: TwoFactorAuthUser) {
    this.httpClient.post<{
      token: string,
      exp: number,
      userID: string,
      role: UserRole,
      firstName: string,
      lastName: string,
      email: string,
      dateOfBirth: Date,
      gender: UserGender,
      phoneNumber: string,
      title: string,
      defaultLanguage: Language
    }>(BACKEND_URL + '/authenticate/two-factor-auth', twoFactorAuthUser)
      .subscribe(
        response => {
          // convert expiration unix epoch time to JS date
          const expirationDate = new Date(response.exp * 1000);

          // authenticate user with auth information from response
          this.authenticateUser(
            response.token,
            expirationDate,
            response.userID,
            response.role,
            response.firstName,
            response.lastName,
            response.email,
            response.dateOfBirth,
            response.gender,
            response.phoneNumber,
            response.defaultLanguage
          );

          // navigate to stored redirect route or home page
          if (this.redirectUrl) {
            this.router.navigateByUrl(this.redirectUrl);
            // reset redirect url
            this.redirectUrl = undefined;
          } else {
            switch (response.role) {
              case UserRole.Customer:
                if (!this.bookingService.getBookingInProgress()) {
                  this.router.navigate(['/home']);
                }
                break;

              case UserRole.CustomerAdmin:
                this.router.navigate(['/home']);
                break;

              case UserRole.Admin:
                this.router.navigate(['/admin']);
                break;
            }
          }
          this.validateTwoFactorAuthStatusListener.next(true);
          // notify subscribers about new auth status
          this.authStatusListener.next(true);
        },
        error => {
          console.log(error);

          this.alertService.createAlert(
            AlertType.Danger,
            this.translateService.instant('app.auth.service.twoFactorAuthFailed')
          );

          this.authStatusListener.next(false);
          this.validateTwoFactorAuthStatusListener.next(false);
        }
      )
  }


  /**
   * REGISTRATION FUNCTIONALITY
   */

  requestRegister2faToken(registerUser: RegisterUser) {
    this.httpClient.post(BACKEND_URL + '/authenticate/register-2fa', registerUser, { responseType: 'text' })
      .subscribe(_ => {
        this.requestRegisterTwoFactorAuthStatusListener.next(true);
      },
      error => {
        console.log(error);
        
        // check the error type
        let failedReason: RegistrationFailedReason = RegistrationFailedReason.Other;

        try {
          const responseError = JSON.parse(error?.error);

          if (error?.status === 409 && responseError?.errorType === 'EmailAlreadyTaken') {
            failedReason = RegistrationFailedReason.EmailAlreadyTaken;
          } else if (error?.status === 400 && responseError?.errorType === 'PasswordConfirmationMismatch') {
            failedReason = RegistrationFailedReason.PasswordsDontMatch;
          }
        } catch (err: any) {
          // best effort
        }

        if (failedReason === RegistrationFailedReason.Other) {
          this.alertService.createAlert(
            AlertType.Danger,
            this.translateService.instant('app.auth.service.register2faFailed')
          );
        }
        
        this.requestRegisterTwoFactorAuthStatusListener.next(failedReason);
      });
  }

  register(registerUser: RegisterUser) {
    this.httpClient.post<{
      token: string,
      exp: number,
      userID: string,
      role: UserRole,
      firstName: string,
      lastName: string,
      email: string,
      dateOfBirth: Date,
      gender: UserGender,
      phoneNumber: string,
      title: string,
      defaultLanguage: Language
    }>(BACKEND_URL + '/authenticate/register', registerUser)
      .subscribe(response => {
        // convert expiration unix epoch time to JS date
        const expirationDate = new Date(response.exp * 1000);

        // store authentication data
        
        this.temporaryUser = {
          token: response.token,
          expirationDate: expirationDate,
          userID: response.userID,
          role: response.role,
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          dateOfBirth: response.dateOfBirth,
          gender: response.gender,
          phoneNumber: response.phoneNumber,
          defaultLanguage: response.defaultLanguage
        }

        if (isMyBloodDrop) {
          this.authenticateUser(
            this.temporaryUser.token,
            this.temporaryUser.expirationDate,
            this.temporaryUser.userID,
            this.temporaryUser.role,
            this.temporaryUser.firstName,
            this.temporaryUser.lastName,
            this.temporaryUser.email,
            this.temporaryUser.dateOfBirth,
            this.temporaryUser.gender,
            this.temporaryUser.phoneNumber,
            this.temporaryUser.defaultLanguage
          );
        }

        // navigate to stored redirect route or home page
        if (this.redirectUrl == null) {
          let url: any[];
          switch (response.role) {
            case UserRole.Customer:
              this.redirectUrl = '/home';
              break;

            case UserRole.CustomerAdmin:
              this.redirectUrl = '/home';
              break;

            case UserRole.Admin:
              this.redirectUrl = '/admin';
              break;
          }
        }

        this.registerStatusListener.next(true);
      },
      error => {
        console.log(error);
        this.alertService.createAlert(
          AlertType.Danger,
          this.translateService.instant('app.auth.service.register2faFailed')
        );
        this.registerStatusListener.next(false);
      });
  }

  navigateToRedirectUrl() {
    if (this.temporaryUser != null) {
      this.authenticateUser(
        this.temporaryUser.token,
        this.temporaryUser.expirationDate,
        this.temporaryUser.userID,
        this.temporaryUser.role,
        this.temporaryUser.firstName,
        this.temporaryUser.lastName,
        this.temporaryUser.email,
        this.temporaryUser.dateOfBirth,
        this.temporaryUser.gender,
        this.temporaryUser.phoneNumber,
        this.temporaryUser.defaultLanguage
      );

      // reset temporary user
      this.temporaryUser = undefined;
    }

    if (this.redirectUrl != null) {
      this.authStatusListener.next(this.isAuthenticated);
      this.router.navigateByUrl(this.redirectUrl);

      // reset redirect url
      this.redirectUrl = undefined;
    }
  }

}
