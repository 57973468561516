import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertType } from '../shared/alerts/alert-type.enum';
import { AlertService } from '../shared/alerts/alert.service';
import { AppointmentStatus } from '../types/enums.model';
// import { instant } from '../shared/shared.module';
import { Appointment, ReportDocument, ReportDocumentFile } from '../types/appointment.model';
import { TranslateService } from '@ngx-translate/core';


const BACKEND_URL = environment.apiUrl;


@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private appointmentsInProgress: Appointment[] | undefined;
  private appointmentsInProgressListener = new Subject<Appointment[]>();

  private upcomingAppointments: Appointment[] | undefined;
  private upcomingAppointmentsListener = new Subject<Appointment[]>();

  private completedAppointments: Appointment[] | undefined;
  private completedAppointmentsListener = new Subject<Appointment[]>();

  private reportDocumentsListener = new Subject<ReportDocument[]>();
  private reportDocumentListener = new Subject<ReportDocumentFile>();

  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }

  /**
   * GETTERS FOR LISTENERS
   */

  getAppointmentsInProgressListener() {
    return this.appointmentsInProgressListener.asObservable();
  }

  getUpcomingAppointmentsListener() {
    return this.upcomingAppointmentsListener.asObservable();
  }

  getCompletedAppointmentsListener() {
    return this.completedAppointmentsListener.asObservable();
  }

  getReportDocumentsListener() {
    return this.reportDocumentsListener.asObservable();
  }

  getReportDocumentListener() {
    return this.reportDocumentListener.asObservable();
  }


  /**
   * BUSINESS LOGIC
   */

  private loadAppointments(status?: AppointmentStatus) {
    this.httpClient.get<{
      appointments: Appointment[]
    }>(`${BACKEND_URL}/home/appointments${status != null ? `/${status}` : ''}`)
    .subscribe(
      response => {
        if (status == null) {
          // if all statuses were requested, split into parts
          this.appointmentsInProgress = [];
          this.upcomingAppointments = [];
          this.completedAppointments = [];

          for (const appointment of response.appointments) {
            if (appointment.status === AppointmentStatus.InProgress) {
              this.appointmentsInProgress.push(appointment);
            } else if (appointment.status === AppointmentStatus.Upcoming) {
              this.upcomingAppointments.push(appointment);
            } else if (appointment.status === AppointmentStatus.Completed || appointment.status === AppointmentStatus.Cancelled) {
              this.completedAppointments.push(appointment);
            }
          }
        } else {
          if (status === AppointmentStatus.InProgress) {
            this.appointmentsInProgress = response.appointments;
          } else if (status === AppointmentStatus.Upcoming) {
            this.upcomingAppointments = response.appointments;
          } else if (status === AppointmentStatus.Completed) {
            this.completedAppointments = response.appointments;
          }
        }

        // notify subscribers about new appointment data
        if (status == null || status === AppointmentStatus.InProgress) {
          this.appointmentsInProgressListener.next([...(this.appointmentsInProgress || [])]);
        }
        if (status == null || status === AppointmentStatus.Upcoming) {
          this.upcomingAppointmentsListener.next([...(this.upcomingAppointments || [])]);
        }
        if (status == null || status === AppointmentStatus.Completed) {
          this.completedAppointmentsListener.next([...(this.completedAppointments || [])]);
        }
      }, error => {
        console.log(error);
        if (status == null || status === AppointmentStatus.InProgress) {
          this.appointmentsInProgressListener.next([]);
        }
        
        if (status == null || status === AppointmentStatus.Upcoming) {
          this.upcomingAppointmentsListener.next([]);
        }
        
        if (status == null || status === AppointmentStatus.Completed) {
          this.completedAppointmentsListener.next([]);
        }

        this.alertService.createAlert(
          AlertType.Danger,
          this.translateService.instant('app.home.service.getAppointmentsRequestFailed'),
          null
        );
      }
    );
  }

  getAppointments(fromCache: boolean = true, status?: AppointmentStatus) {
    if (
      fromCache &&
      (
        (status === AppointmentStatus.InProgress && this.appointmentsInProgress != null) ||
        (status === AppointmentStatus.Upcoming && this.upcomingAppointments != null) ||
        (status === AppointmentStatus.Completed && this.completedAppointments != null) ||
        (status == null && this.appointmentsInProgress != null && this.upcomingAppointments != null && this.completedAppointments != null)
      )
    ) {
      if (status == null || status === AppointmentStatus.InProgress) {
        this.appointmentsInProgressListener.next([...(this.appointmentsInProgress || [])]);
      }

      if (status == null || status === AppointmentStatus.Upcoming) {
        this.upcomingAppointmentsListener.next([...(this.upcomingAppointments || [])]);
      }

      if (status == null || status === AppointmentStatus.Completed) {
        this.completedAppointmentsListener.next([...(this.completedAppointments || [])]);
      }
    } else {
      this.loadAppointments(status);
    }
  }

  getDocumentListForAppointment(appointmentID: string) {
    // try to find appointment in locally stored lists
    let appointment: Appointment | undefined = this.completedAppointments?.find(appointment => appointment.appointmentID === appointmentID);
    if (appointment == null) {
      this.appointmentsInProgress?.find(appointment => appointment.appointmentID === appointmentID);
    }
    if (appointment == null) {
      this.upcomingAppointments?.find(appointment => appointment.appointmentID === appointmentID);
    }

    if (appointment != null && appointment.reportDocuments != null) {
      this.reportDocumentsListener.next(appointment.reportDocuments);
      return;
    }

    // data not locally stored - fetch from server
    this.httpClient.get<{
      reportDocuments: ReportDocument[]
    }>(`${BACKEND_URL}/appointment/${appointmentID}/report`)
    .subscribe(
      response => {
        if (appointment != null) {
          appointment.reportDocuments = response.reportDocuments;
        }

        // notify subscribers about new report document data
        this.reportDocumentsListener.next([...(appointment?.reportDocuments || [])]);
      }, error => {
        console.log(error);

        this.alertService.createAlert(
          AlertType.Danger,
          this.translateService.instant('app.home.service.getReportDocumentsListFailed'),
          null
        );

        this.reportDocumentsListener.next([]);
      }
    );
  }

  getReportDocument(appointmentID: string, documentID: number) {
    this.httpClient.get(
      `${BACKEND_URL}/appointment/${appointmentID}/report/${documentID}`,
      {
        observe: 'response',
        responseType: 'arraybuffer'
      })
    .subscribe(
      response => {
        // parse filename from Content-Disposition header
        const filenameMatch = /filename="(.*?)"/.exec(response.headers.get('Content-Disposition') || '');
        let filename: string | undefined;
        if (filenameMatch != null) {
          filename = filenameMatch[1];
        }

        // notify subscribers about new report document
        this.reportDocumentListener.next({
          filename,
          data: response.body
        });
      }, error => {
        console.log(error);

        this.alertService.createAlert(
          AlertType.Danger,
          this.translateService.instant('app.home.service.getReportDocumentFailed'),
          null
        );

        this.reportDocumentListener.next(undefined);
      }
    );
  }


  /*
   * Helper Functions
   */

  cleanup() {
    this.appointmentsInProgress = undefined;
    this.upcomingAppointments = undefined;
    this.completedAppointments = undefined;
  }

}
