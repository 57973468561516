<form
    *ngIf="generatedForm != null && form != null && form.formFields != null"
    [formGroup]="generatedForm"
    (ngSubmit)="onFormSubmit()">

    <!-- Build dynamic form from formFields -->
    <div
        *ngFor="let formField of form.formFields"
        [class]="'pvm-form-field' + (formField.fieldWidth != null ? ' box-sizing-border-box d-inline-block ' + formField.fieldWidth : ' w-100') + (formField.fieldPlacement != null ? ' ' + formField.fieldPlacement : '')">

        <!-- Header text -->
        <div 
            *ngIf="formField.fieldType === 'header'"
            class="pvm-header">
            <span [innerHtml]="selectedLanguage !== 'en' ? formField.fieldLabelDE : formField.fieldLabelEN "></span>
        </div>

        <!-- Text Input -->
        <input
            *ngIf="formField.fieldType === 'text'"
            class="pvm-input"
            [id]="formField.fieldName"
            type="text"
            [attr.aria-labelledby]="formField.fieldName + 'Label'"
            [attr.aria-describedby]="formField.fieldName + 'Error'"
            placeholder="{{ selectedLanguage !== 'en' ? formField.fieldLabelDE : formField.fieldLabelEN }}"
            [formControlName]="formField.fieldName"
            [value]="formField.initialValue">

        <!-- Multiline Text Input -->
        <textarea
            *ngIf="formField.fieldType === 'multiline-text'"
            class="pvm-input"
            [id]="formField.fieldName"
            [attr.aria-labelledby]="formField.fieldName + 'Label'"
            [attr.aria-describedby]="formField.fieldName + 'Error'"
            placeholder="{{ selectedLanguage !== 'en' ? formField.fieldLabelDE : formField.fieldLabelEN }}"
            [formControlName]="formField.fieldName"></textarea>

        <!-- Number Input -->
        <input
            *ngIf="formField.fieldType === 'number'"
            class="pvm-input"
            [id]="formField.fieldName"
            type="number"
            [attr.aria-labelledby]="formField.fieldName + 'Label'"
            [attr.aria-describedby]="formField.fieldName + 'Error'"
            placeholder="{{ selectedLanguage !== 'en' ? formField.fieldLabelDE : formField.fieldLabelEN }}"
            [formControlName]="formField.fieldName">

        <!-- Date Input -->
        <input
            *ngIf="formField.fieldType === 'date'"
            class="pvm-input"
            [id]="formField.fieldName"
            type="date"
            [attr.aria-labelledby]="formField.fieldName + 'Label'"
            [attr.aria-describedby]="formField.fieldName + 'Error'"
            placeholder="{{ selectedLanguage !== 'en' ? formField.fieldLabelDE : formField.fieldLabelEN }}"
            [formControlName]="formField.fieldName">

        <!-- Select Input -->
        <select
            *ngIf="formField.fieldType === 'select'"
            class="pvm-input"
            [ngClass]="{ 'is-placeholder': generatedForm?.get(formField.fieldName)?.value == null }"
            [id]="formField.fieldName"
            [attr.aria-labelledby]="formField.fieldName + 'Label'"
            [attr.aria-describedby]="formField.fieldName + 'Error'"
            [formControlName]="formField.fieldName">
            <option [ngValue]="null">{{ selectedLanguage !== 'en' ? formField.fieldLabelDE : formField.fieldLabelEN }}</option>
            <option
                *ngFor="let selectOption of formField.selectOptions"
                [ngValue]="selectOption.value">{{ selectedLanguage !== 'en' ? selectOption.labelDE : selectOption.labelEN }}</option>
        </select>

        <!-- Toggle Input -->
        <div
            *ngIf="formField.fieldType === 'toggle'"
            class="toggle-wrapper">
            <input
                [id]="formField.fieldName"
                type="checkbox"
                class="pvm-toggle"
                [formControlName]="formField.fieldName">

            <span [innerHtml]="selectedLanguage !== 'en' ? formField.fieldLabelDE : formField.fieldLabelEN"></span>
        </div>

        <!-- Checkbox Input -->
        <div
            *ngIf="formField.fieldType === 'checkbox'"
            class="checkbox-wrapper">
            <input
                [id]="formField.fieldName"
                type="checkbox"
                class="pvm-checkbox"
                [formControlName]="formField.fieldName">

            <span [innerHtml]="selectedLanguage !== 'en' ? formField.fieldLabelDE : formField.fieldLabelEN"></span>
        </div>

        <!-- Checkbox Select Input -->
        <div
            *ngIf="formField.fieldType === 'checkbox-select'"
            class="checkbox-select-wrapper"
            [formGroupName]="formField.fieldName">
            <label [id]="formField.fieldName + 'Label'">{{ selectedLanguage !== 'en' ? formField.fieldLabelDE : formField.fieldLabelEN }}</label>

            <div
                *ngFor="let selectOption of formField.selectOptions"
                class="checkbox-wrapper">
                <input
                    [id]="selectOption.value"
                    type="checkbox"
                    class="pvm-checkbox"
                    [formControlName]="selectOption.value">

                <span [innerHtml]="selectedLanguage !== 'en' ? selectOption.labelDE : selectOption.labelEN"></span>
            </div>

            <div
                *ngIf="(selectedLanguage !== 'en' && formField.validationErrorMessageDE != null) || (selectedLanguage === 'en' && formField.validationErrorMessageEN != null)"
                [ngClass]="{ 'pvm-input-error-fixed-active': generatedForm?.get(formField.fieldName)?.invalid && allSubControlsDirty(formField) }"
                class="pvm-input-error-fixed"
                [id]="formField.fieldName + 'Error'">
                <span>{{ selectedLanguage !== 'en' ? formField.validationErrorMessageDE : formField.validationErrorMessageEN }}</span>
            </div>
        </div>

        <!-- Radio Button Select Input -->
        <div
            *ngIf="formField.fieldType === 'radio-button-select'"
            class="radio-button-select-wrapper">
            <label [id]="formField.fieldName + 'Label'">{{ selectedLanguage !== 'en' ? formField.fieldLabelDE : formField.fieldLabelEN }}</label>

            <div
                *ngFor="let selectOption of formField.selectOptions"
                class="radio-button-wrapper">
                <input
                    [id]="selectOption.value"
                    type="radio"
                    class="pvm-radio-button"
                    [value]="selectOption.value"
                    [formControlName]="formField.fieldName">

                <span [innerHtml]="selectedLanguage !== 'en' ? selectOption.labelDE : selectOption.labelEN"></span>
            </div>
        </div>
    
        <div
            *ngIf="formField.fieldType !== 'header' && formField.fieldType !== 'toggle' && formField.fieldType !== 'checkbox' && formField.fieldType !== 'checkbox-select' && formField.fieldType !== 'radio-button-select'"
            class="pvm-input-label"
            [id]="formField.fieldName + 'Label'"
            [ngClass]="{ 'pvm-input-label-active': formField.fieldType === 'date' || (generatedForm?.get(formField.fieldName)?.value || '') != '' }">
            <span>{{ selectedLanguage !== 'en' ? formField.fieldLabelDE : formField.fieldLabelEN }}</span>
        </div>

        <div
            *ngIf="
                ((selectedLanguage !== 'en' && formField.validationErrorMessageDE != null) || (selectedLanguage === 'en' && formField.validationErrorMessageEN != null)) &&
                formField.fieldType !== 'toggle' && formField.fieldType !== 'checkbox' && formField.fieldType !== 'checkbox-select' && formField.fieldType !== 'radio-button-select'"
            [ngClass]="{ 'pvm-input-error-active': generatedForm?.get(formField.fieldName)?.invalid && generatedForm?.get(formField.fieldName)?.touched }"
            class="pvm-input-error"
            [id]="formField.fieldName + 'Error'">
                <span>{{ selectedLanguage !== 'en' ? formField.validationErrorMessageDE : formField.validationErrorMessageEN }}</span>
        </div>
        
    </div>

    <div class="d-flex justify-content-center pt-2">
        <button type="submit" [class]="submitButtonClass" [disabled]="generatedForm?.invalid || disabled">
            <span>{{ submitButtonText }}</span>
        </button>
    </div>

    <div
        class="d-flex pt-4"
        [ngClass]="{
            'justify-content-end': !hasCancelButton,
            'justify-content-between': hasCancelButton
        }">
        <button
            *ngIf="hasCancelButton"
            type="button"
            [class]="cancelButtonClass"
            [ngClass]="{
                'd-flex': cancelButtonIcon != null,
                'justify-content-start': cancelButtonIcon != null,
                'align-items-center': cancelButtonIcon != null
            }"
            (click)="onCancelButtonClick()"
            [disabled]="disabled">
            <mat-icon *ngIf="cancelButtonIcon != null" class="material-icons-outlined custom-icon">{{ cancelButtonIcon }}</mat-icon>
            <span>{{ cancelButtonText }}</span>
        </button>
    </div>

</form>