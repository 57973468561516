import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ProfileData, Slot, SlotData, Profile, BusinessEngagement, AnamnesisQuestion, AnamnesisData, ProfileGroup } from '../../types/business-engagement.types';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/shared/alerts/alert.service';
import { AlertType } from 'src/app/shared/alerts/alert-type.enum';
// import { instant } from 'src/app/shared/shared.module';
import { BookingFailedReason, BookingInvalidEngagementReason } from 'src/app/types/enums.model';
import { TranslateService } from '@ngx-translate/core';


const BACKEND_URL = environment.apiUrl;


@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private businessEngagementID: string | undefined;

  private businessEngagement: BusinessEngagement | undefined;
  private availableProfileGroups: ProfileGroup[] = [];
  private anamnesisQuestions: AnamnesisQuestion[] | undefined;
  private availableSlots: Slot[] = [];
  private defaultProfile: Profile | undefined;
  private bookingInProgress = false;

  private businessEngagementListener = new Subject<BusinessEngagement>();
  private availableProfileGroupsListener = new Subject<ProfileGroup[]>();
  private anamnesisQuestionsListener = new Subject<AnamnesisQuestion[] | undefined>();
  private availableSlotsListener = new Subject<Slot[]>();
  private invalidEngagementListener = new Subject<BookingInvalidEngagementReason>();
  private defaultProfileListener = new Subject<Profile>();
  
  private profileDataListener = new Subject<ProfileData>();
  private anamnesisDataListener = new Subject<AnamnesisData>();
  private slotDataListener = new Subject<SlotData>();


  private bookingInProgressListener = new Subject<boolean>();
  private bookingSuccessfulListener = new Subject<boolean>();
  private bookingFailedListener = new Subject<BookingFailedReason>();


  private profileData: ProfileData = {
    selectedProfiles: []
  };

  private anamnesisData: AnamnesisData = {
    anamnesisAnswers: []
  }

  private slotData: SlotData = {
    selectedSlot: undefined
  }

  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }

  get hasBusinessEngagementID() {
    return this.businessEngagementID != null;
  }

  getBusinessEngagementID() {
    return this.businessEngagementID;
  }

  getBusinessEngagementListener() {
    return this.businessEngagementListener.asObservable();
  }

  getAvailableProfileGroupsListener() {
    return this.availableProfileGroupsListener.asObservable();
  }

  getAnamnesisQuestionsListener() {
    return this.anamnesisQuestionsListener.asObservable();
  }

  getAvailableSlotsListener() {
    return this.availableSlotsListener.asObservable();
  }

  getInvalidEngagementListener() {
    return this.invalidEngagementListener.asObservable();
  }

  getProfileDataListener() {
    return this.profileDataListener.asObservable();
  }

  getAnamnesisDataListener() {
    return this.anamnesisDataListener.asObservable();
  }

  getSlotDataListener() {
    return this.slotDataListener.asObservable();
  }

  getBookingInProgress() {
    return this.bookingInProgress;
  }

  getBookingInProgressListener() {
    return this.bookingInProgressListener.asObservable();
  }

  getBookingSuccessfulListener() {
    return this.bookingSuccessfulListener.asObservable();
  }

  getBookingFailedListener() {
    return this.bookingFailedListener.asObservable();
  }

  getDefaultProfileListener() {
    return this.defaultProfileListener.asObservable();
  }

  setBusinessEngagementID(engagementID: string) {
    this.bookingInProgress = true
    this.businessEngagementID = engagementID;
    this.availableProfileGroups = [];
    this.anamnesisQuestions = [];
    this.availableSlots = [];
    this.profileData = { selectedProfiles: [] };
    this.slotData = { selectedSlot: undefined };

    if (this.businessEngagementID != null) {
      // engagement id provided -- load engagement data from backend
      this.httpClient.get<{
        engagement: BusinessEngagement,
        profileGroups: ProfileGroup[],
        slots: Slot[]
      }>(BACKEND_URL + '/business-engagement/' + this.businessEngagementID)
      .subscribe(
        result => {
          this.businessEngagement = result.engagement;
          this.availableProfileGroups = result.profileGroups;
          this.availableSlots = result.slots;

          this.bookingInProgressListener.next(this.bookingInProgress);
          this.businessEngagementListener.next({...this.businessEngagement});
          this.availableProfileGroupsListener.next([...(this.availableProfileGroups || [])]);
          this.availableSlotsListener.next([...(this.availableSlots || [])]);
        }, error => {
          // check which kind of error occured
          let invalidReason: BookingInvalidEngagementReason = BookingInvalidEngagementReason.Other;

          if (error?.status === 404 && error?.error?.errorType === 'businessEngagement') {
            invalidReason = BookingInvalidEngagementReason.EngagementDoesntExist;
          } else if (error?.status === 409) {
            switch (error?.error?.errorType) {
              case 'AppointmentBookingClosed':
                invalidReason = BookingInvalidEngagementReason.EngagementBookingClosed;
                break;

              case 'AppointmentFullyBooked':
                invalidReason = BookingInvalidEngagementReason.EngagementFullyBooked;
                break;

              case 'AppointmentAlreadyBooked':
                invalidReason = BookingInvalidEngagementReason.AppointmentAlreadyExists;
                break;

              default:
                break;
            }
          }

          this.invalidEngagementListener.next(invalidReason);
        }
      );
    }
  }


  /**
   * PROFILES
   */

  getAvailableProfileGroups() {
    return [...(this.availableProfileGroups || [])];
  }

  getSelectedProfiles() {
    return this.profileData.selectedProfiles.map(profile => profile.profileID);
  }

  setSelectedProfiles(selectedProfiles: Profile[]) {
    this.profileData.selectedProfiles = selectedProfiles;

    // notify subscribers about new data
    this.profileDataListener.next({ ...this.profileData });

    // profiles were changed -- (re)load anamnesis
    if (this.businessEngagementID != null) {
      this.loadAnamnesisQuestions(this.businessEngagementID, selectedProfiles.map(profile => profile.profileID));
    }
  }

  private loadDefaultProfileForBusinessEngagement(businessEngagementID: string) {
    if (businessEngagementID == null) {
      return
    }
    this.httpClient.get<{ profileResult: Profile[] }>(BACKEND_URL + '/business-engagement/' + this.businessEngagementID + '/default-profile')
    .subscribe(
      result => {
        this.defaultProfile = result.profileResult[0]
        this.defaultProfileListener.next(this.defaultProfile)
        this.setSelectedProfiles([this.defaultProfile])
        // Load anamnesis with default profile data
        //this.loadAnamnesisQuestions(businessEngagementID, [this.defaultProfile.profileID])
      }, error => {
        this.alertService.createAlert(
          AlertType.Danger,
          this.translateService.instant('app.booking.service.loadingDefaultProfileFailed'),
          null
        )
        this.defaultProfileListener.next()
      }
    )
  }

  getOrLoadDefaultProfileForBusinessEngagement(businessEngagementID: string) {
    if (this.defaultProfile != null) {
      this.defaultProfileListener.next(this.defaultProfile)
    } else {
      this.loadDefaultProfileForBusinessEngagement(businessEngagementID)
    }
  }


  /**
   * ANAMNESIS
   */

  private loadAnamnesisQuestions(businessEngagementID: string, selectedProfiles: number[]) {
    if (businessEngagementID == null || selectedProfiles.length === 0) {
      this.anamnesisQuestionsListener.next([]);
    }

    // let subscribers know that anamnesis data is being loaded
    this.anamnesisQuestionsListener.next(undefined);

    this.httpClient.get<{
      anamnesisQuestions: AnamnesisQuestion[]
    }>(BACKEND_URL + '/business-engagement/' + this.businessEngagementID + '/anamnesis?' + selectedProfiles.map(profileID => 'profile=' + profileID.toString()).join('&'))
    .subscribe(
      result => {
        this.anamnesisQuestions = result.anamnesisQuestions;

        this.anamnesisQuestionsListener.next([ ...this.anamnesisQuestions ]);
      }, error => {
        this.alertService.createAlert(
          AlertType.Danger,
          this.translateService.instant('app.booking.service.loadingAnamnesisFailed'),
          null
        );
        this.anamnesisQuestionsListener.next([]);
      }
    );
  }

  getAnamnesisQuestions() {
    return this.anamnesisQuestions == null ? undefined : [ ...this.anamnesisQuestions ];
  }

  storeAnamnesisAnswers(anamnesisAnswers: AnamnesisQuestion[]) {
    this.anamnesisData.anamnesisAnswers = anamnesisAnswers;
    this.anamnesisDataListener.next({ ...this.anamnesisData })
  }

  setAnamnesisAnswers(anamnesisAnswers: AnamnesisQuestion[]) {
    this.httpClient.put(
      BACKEND_URL + '/profile/anamnesis',
      { anamnesisAnswers: anamnesisAnswers.map(anamnesisAnswers => { return { questionID: anamnesisAnswers.questionID, answer: anamnesisAnswers.answer } }) },
      { responseType: 'text' }
    )
    .subscribe(
      _ => {
        this.anamnesisData.anamnesisAnswers = anamnesisAnswers;

        // notify subscribers about new data
        this.anamnesisDataListener.next({ ...this.anamnesisData });
      }, error => {
        this.alertService.createAlert(
          AlertType.Danger,
          this.translateService.instant('app.booking.service.savingAnamnesisFailed'),
          null
        );
        this.anamnesisDataListener.next({ ...this.anamnesisData });
      }
    );
  }


  /**
   * SLOTS
   */

  getAvailableSlots() {
    return [...(this.availableSlots || [])];
  }

  setSelectedSlot(selectedSlot: Slot) {
    this.slotData.selectedSlot = selectedSlot;

    // notify subscribers about new data
    this.slotDataListener.next({ ...this.slotData });
  }



  getProfileData() {
    return this.profileData;
  }

  getAnamnesisData() {
    return this.anamnesisData;
  }

  getSlotData() {
    return this.slotData;
  }


  createEngagement(businessEngagementID: string, profileData: ProfileData, slotData: SlotData) {
    console.log(businessEngagementID)
    console.log(profileData)
    console.log(slotData)
    if (
      this.businessEngagementID == null || this.businessEngagementID !== businessEngagementID ||
      profileData?.selectedProfiles?.length < 1 ||
      slotData?.selectedSlot?.slotID == null || slotData?.selectedSlot?.slotID < 0
    ) {
      return;
    }

    let requestData: any = {
      profiles: profileData?.selectedProfiles?.map(profile => profile.profileID),
      slot: slotData?.selectedSlot?.slotID
    }
    this.httpClient.post(BACKEND_URL + '/business-engagement/' + this.businessEngagementID, requestData, { responseType: 'text' })
      .subscribe(
        _ => {
          // booking successful
          this.bookingInProgress = false;
          this.bookingSuccessfulListener.next(true);
          this.bookingInProgressListener.next(this.bookingInProgress);
        }, error => {
          // booking failed
          let failedReason: BookingFailedReason = BookingFailedReason.Other;

          if (error?.status === 409) {
            switch (error?.error?.errorType) {
              case 'SlotAlreadyFull':
                failedReason = BookingFailedReason.SlotAlreadyFull;
                break;

              default:
                break;
            }
          }

          this.bookingFailedListener.next(failedReason);
        });
  }

}
