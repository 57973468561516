enum DynamicFormFieldType {
    Header = 'header',
    Text = 'text',
    MultilineText = 'multiline-text',
    Number = 'number',
    Date = 'date',
    Select = 'select',
    Toggle = 'toggle',
    CheckBox = 'checkbox',
    CheckBoxSelect = 'checkbox-select',
    RadioButtonSelect = 'radio-button-select'
}

enum DynamicFormFieldValidatorType {
    Required = 'required',
    RequiredTrue = 'required-true',
    Min = 'min',
    Max = 'max',
    MinLength = 'min-length',
    MaxLength = 'max-length',
    Email = 'email',
    Pattern = 'pattern'
}

interface DynamicFormFieldValidator {
    validatorID?:any;
    validatorName?: string;
    validatorType: DynamicFormFieldValidatorType;
    validatorArgument?: any;
    isSelected?: any;
}

interface DynamicFormSelectOption {
    optionID?:any;
    optionName?: string;
    labelDE: string;
    labelEN: string;
    value: any;
    optionValidators?: DynamicFormFieldValidator[];
    isSelected?:any;
}

enum DynamicFormFieldWidth {
    Full = 'w-100',
    ThreeQuarters = 'w-75',
    Half = 'w-50',
    Quarter = 'w-25'
}

enum DynamicFormFieldPlacement {
    Left = 'pe-1',
    Middle = 'px-1',
    Right = 'ps-1'
}

interface DynamicFormField {
    questionID?: number,
    questionName?: string,
    fieldName: string;
    fieldLabelDE: string;
    fieldLabelEN: string;
    fieldType: DynamicFormFieldType;
    fieldWidth?: DynamicFormFieldWidth;
    fieldPlacement?: DynamicFormFieldPlacement;
    placeholderDE?: string;
    placeholderEN?: string;
    initialValue?: any;
    fieldValidators?: DynamicFormFieldValidator[];
    validationErrorMessageDE?: string;
    validationErrorMessageEN?: string;
    selectOptions?: DynamicFormSelectOption[];
    sortIndex?: number;
}

interface DynamicForm {
    formFields: DynamicFormField[];
}


export {
    DynamicFormFieldType,
    DynamicFormFieldValidatorType,
    DynamicFormFieldValidator,
    DynamicFormSelectOption,
    DynamicFormFieldWidth,
    DynamicFormFieldPlacement,
    DynamicFormField,
    DynamicForm
}
