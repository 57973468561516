import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../auth/shared/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UserRole } from '../types/enums.model';
import { ThemeService } from '../shared/theme.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  languageSelectionExpanded: boolean = false;

  isMyBloodDrop: boolean | undefined;

  isAuthenticated = false;
  role: UserRole | undefined;

  private authStatusSub: Subscription | undefined;
  private themeSub: Subscription | undefined;
  
  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private themeService : ThemeService,
  ) { }

  ngOnInit(): void {
    this.themeSub = this.themeService.getIsMyBloodDropListener().subscribe(
      theme => {
        this.isMyBloodDrop = theme
      }
    )
    
    this.isAuthenticated = this.authService.getIsAuthenticated();

    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(
      status => {
        this.isAuthenticated = status;
        this.role = this.authService.getRole();
      }
    );

    this.themeService.getOrLoadTheme();
  }

  toggleExpanded() {
    this.languageSelectionExpanded = !this.languageSelectionExpanded;
  }

  setLanguage(lang: string) {
    this.translateService.use(lang);
    this.languageSelectionExpanded = false;
  }

  onLogout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.authStatusSub?.unsubscribe();
    this.themeSub?.unsubscribe();
  }

  get selectedLanguage() {
    return this.translateService.currentLang;
  }
}
