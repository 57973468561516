import { environment } from './../../../environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { Alert } from './alert.model';
import { AlertService } from './alert.service';

const MAX_SIMULTANEOUS_ALERTS = environment.maxSimultaneousAlerts;

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit, OnDestroy {

  alerts: Alert[] = [];
  private alertSub = new Subscription();

  constructor(
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.alertService.getAlertListener().subscribe(
      alert => {
        this.addAlert(alert);
      }
    );
  }

  ngOnDestroy(): void {
    this.alertSub?.unsubscribe();
  }

  onAlertClosed(alertId: number | undefined) {
    if (alertId != null) {
      this.removeAlertById(alertId);
    }
  }

  private addAlert(alert: Alert) {
    // if max number of alerts reached, remove the one with shortest time left
    if (this.alerts.length >= MAX_SIMULTANEOUS_ALERTS) {
      const shortestTime = Math.min(...this.alerts.map( a => a.dismissedAfter || 9999 ));
      let removeIndex = this.alerts.findIndex( a => a.dismissedAfter === shortestTime);
      if (removeIndex < 0) {
        removeIndex = 0;
      }
      this.removeAlertByIndex(removeIndex);
    }

    // get unique id for alert
    const newId = this.getUniqueAlertId();
    alert.id = newId;
    this.alerts.push(alert);
  }

  private removeAlertById(alertId: number) {
    const removeIndex = this.alerts.findIndex( a => a.id === alertId );
    if (removeIndex >= 0) {
      this.alerts.splice(removeIndex, 1);
    }
  }

  private removeAlertByIndex(alertIndex: number) {
    if (alertIndex >= 0 && alertIndex < this.alerts.length) {
      this.alerts.splice(alertIndex, 1);
    }
  }

  private getUniqueAlertId(): number {
    return this.alerts.length === 0 ? 0 : Math.max(...this.alerts.map( a => a.id || 0 )) + 1;
  }

}
