import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { Alert } from './alert.model';
import { AlertType } from './alert-type.enum';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private alertListener = new Subject<Alert>();

  constructor() { }

  getAlertListener() {
    return this.alertListener.asObservable();
  }

  createAlert(type: AlertType, message: string, dismissedAfter: number | null = 3000) {
    this.alertListener.next({
      type,
      message,
      dismissedAfter,
      id: undefined
    });
  }

  /*
   * Helper Functions
   */

  cleanup() {
    // currently, no data stored in service
    // keep this stub for potential future cleanup task
  }
}
