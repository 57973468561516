<ng-container *ngIf="isMyBloodDrop; then myBloodDropHeader else previmoHeader"></ng-container>

<ng-template #previmoHeader>
    <div class="logo-container" [ngClass]="{ 'is-authenticated': isAuthenticated }">
        <img src="assets/svg/previmo-logo.svg">
    </div>
    
    <div *ngIf="isAuthenticated" class="header-container">
        <button mat-stroked-button color="warn" aria-label="more" (click)="onLogout()">
            {{ 'app.logoutButtonText' | translate }}
        </button>
    </div>
    
    <div class="language-selection-container" [ngClass]="{
        'expanded': languageSelectionExpanded,
        'is-authenticated': isAuthenticated
      }">
        <div class="d-flex justify-content-between" (click)="toggleExpanded()">
            <span class="fi" [ngClass]="{
            'fi-de': selectedLanguage === 'de',
            'fi-gb': selectedLanguage === 'en'
          }"></span>
    
            <img class="dropdown-arrow" [ngClass]="{ 'expanded': languageSelectionExpanded }"
                src="assets/svg/arrow-head-left.svg">
        </div>
    
        <div class="dropdown-container" [ngClass]="{ 'expanded': languageSelectionExpanded }">
            <hr>
            <div (click)="setLanguage('de')">
                <span class="fi fi-de"></span>
                <span class="ms-2">Deutsch</span>
            </div>
            <div class="mt-2" (click)="setLanguage('en')">
                <span class="fi fi-gb"></span>
                <span class="ms-2">English</span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #myBloodDropHeader>
    <div class="mbd-header-container">
        <div class="mbd-logo-container" [ngClass]="{ 'is-authenticated': isAuthenticated }">
            <img src="assets/svg/mbd-logo.svg">
        </div>
        <!-- logout assigned to sidebar button for easier testing of auth and non auth booking -->
        <div class="sidebar-icon-container">
            <button mat-icon-button (click)="onLogout()">
                <mat-icon class="material-icons-round custom-icon">menu</mat-icon>
            </button>
        </div>
    </div>
    
    
    <!-- <div *ngIf="isAuthenticated" class="header-container">
        <button mat-stroked-button color="warn" aria-label="more" (click)="onLogout()">
            {{ 'app.logoutButtonText' | translate }}
        </button>
    </div> -->
    
    <!-- <div class="language-selection-container" [ngClass]="{
            'expanded': languageSelectionExpanded,
            'is-authenticated': isAuthenticated
          }">
        <div class="d-flex justify-content-between" (click)="toggleExpanded()">
            <span class="fi" [ngClass]="{
                'fi-de': selectedLanguage === 'de',
                'fi-gb': selectedLanguage === 'en'
              }"></span>
    
            <img class="dropdown-arrow" [ngClass]="{ 'expanded': languageSelectionExpanded }"
                src="assets/svg/arrow-head-left.svg">
        </div>
    
        <div class="dropdown-container" [ngClass]="{ 'expanded': languageSelectionExpanded }">
            <hr>
            <div (click)="setLanguage('de')">
                <span class="fi fi-de"></span>
                <span class="ms-2">Deutsch</span>
            </div>
            <div class="mt-2" (click)="setLanguage('en')">
                <span class="fi fi-gb"></span>
                <span class="ms-2">English</span>
            </div>
        </div>
    </div> -->
</ng-template>

