import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-calender-icon',
  templateUrl: './calender-icon.component.html',
  styleUrls: ['./calender-icon.component.scss']
})
export class CalenderIconComponent implements OnInit {

  @Input('date') date: Date | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
