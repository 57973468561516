import { Component } from '@angular/core';
import { NavigationStart, Router, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './auth/shared/auth.service';
// import { useLang, getLang } from './shared/shared.module';
import { UserRole } from './types/enums.model';
import { AdminNavigation, CustomerAdminNavigation, CustomerNavigation, NavigationItem } from './types/navigation.model';

import { Buffer } from "buffer";
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from './shared/theme.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Previmo Kundenportal';

  languageSelectionExpanded: boolean = false;

  isAuthenticated = false;
  role: UserRole | undefined;
  navigationItems: NavigationItem[] = [];
  activeNavigationIndex: number = 0;

  isMyBloodDrop = environment.isMyBloodDrop;

  private authStatusSub: Subscription | undefined;

  constructor(
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private themeService: ThemeService
  ) { }

  ngOnInit(): void {
    (window as any).global = window;
    // @ts-ignore
    window.Buffer = window.Buffer || Buffer;

    // setup translation service
    this.translateService.addLangs(['de', 'en']);
    this.translateService.setDefaultLang('de');

    // try to automatically login user
    this.authService.autoLogin();

    // if user was not logged in, set language according to browser language
    if (!this.authService.getIsAuthenticated()) {
      const browserLang = this.translateService.getBrowserLang() || 'de';
      this.translateService.use(browserLang.match(/de|en/) ? browserLang : 'de');
    }

    // set initial status and username synchronously
    this.isAuthenticated = this.authService.getIsAuthenticated();
    this.role = this.authService.getRole();
    this.setNavigationItemsByRole(this.role);

    // subscribe to changes to auth status
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(
      status => {
        this.isAuthenticated = status;
        this.role = this.authService.getRole();
        this.setNavigationItemsByRole(this.role);
      }
    );

    // listen to routing events
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        const navigationStartEvent = event as NavigationStart;
        // search for correct navigation item
        for (let i = 0; i < this.navigationItems.length; i++) {
          if ((this.navigationItems[i]?.route?.join('/') || '').startsWith(navigationStartEvent.url)) {
            this.activeNavigationIndex = i;
          }
        }
      }
    });

    // set theme 
    this.themeService.setTheme(this.isMyBloodDrop);
  }

  setNavigationItemsByRole(role: UserRole | undefined) {
    switch (role) {
      case UserRole.Customer:
        this.navigationItems = CustomerNavigation;
        break;

      case UserRole.CustomerAdmin:
        this.navigationItems = CustomerAdminNavigation;
        break;
      
      case UserRole.Admin:
        this.navigationItems = AdminNavigation;
        break;
      
      default:
        this.navigationItems = [];
        break;
    }
  }

  toggleExpanded() {
    this.languageSelectionExpanded = !this.languageSelectionExpanded;
  }

  setLanguage(lang: string) {
    this.translateService.use(lang);
    this.languageSelectionExpanded = false;
  }

  onLogout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.authStatusSub?.unsubscribe();
  }

  get selectedLanguage() {
    return this.translateService.currentLang;
  }
}
