import { YesNoDialogResult } from './yes-no-dialog-result.enum';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss']
})
export class YesNoDialogComponent implements OnInit {

  comment: string | undefined;

  constructor(
    public dialogRef: MatDialogRef<YesNoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      i18nTitleKey: string,
      messageLines?: string[],
      i18nMessageKeys?: string[],
      yesText?: string,
      i18nYesKey?: string,
      noText?: string,
      i18nNoKey?: string,
      commentAvailable: boolean,
      commentMandatory?: boolean,
      commentTitle?: string,
      i18nCommentTitleKey?: string
    }
  ) { }

  ngOnInit(): void {
  }

  closeDialog(result: 'yes' | 'no' | 'cancel') {
    this.dialogRef.close({
      dialogResult: result as YesNoDialogResult,
      comment: this.comment
    });
  }

}
