import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { UserRole } from 'src/app/types/enums.model';

import { AuthService } from '../shared/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthLoggedInGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const isAuthenticated = this.authService.getIsAuthenticated();
    if (isAuthenticated) {
      // user is already authenticated, redirect to home page
      const role = this.authService.getRole();

      switch (role) {
        case UserRole.Customer:
          this.router.navigate(['/home']);
          break;

        case UserRole.CustomerAdmin:
          this.router.navigate(['/home']);
          break;

        case UserRole.Admin:
          this.router.navigate(['/admin']);
          break;
      }
    }

    return !isAuthenticated;
  }

}
