<div class="alert {{ type }} alert-dismissible fade show mt-1 mb-1" role="alert">
    <span>{{ message }}</span>
    <button
        mat-icon-button
        (click)="close()"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        #btnClose>
        <mat-icon>close</mat-icon>
    </button>
</div>
