import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserRole } from 'src/app/types/enums.model';
import { AuthService } from '../../shared/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      // get required roles from route snapshot
      const permittedRoles = next?.data?.permittedRoles as UserRole[];
      if (permittedRoles == null) {
        return false;
      }

      const role = this.authService.getRole();
      if (permittedRoles == null || role == null || !permittedRoles.includes(role)) {
        if (this.authService.getIsAuthenticated()) {
          switch (role) {
            case UserRole.Customer:
              this.router.navigate(['/home']);
              break;

            case UserRole.CustomerAdmin:
              this.router.navigate(['/home']);
              break;

            case UserRole.Admin:
              this.router.navigate(['/admin']);
              break;
          }
        } else {
          this.authService.setRedirectUrl(state.url);
          this.router.navigate(['/auth']);
        }
      }

      return permittedRoles != null && role != null && permittedRoles.includes(role);
  }

}
