import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from '../shared/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const isAuthenticated = this.authService.getIsAuthenticated();
    if (!isAuthenticated) {
      // user is not authenticated, redirect to login
      // (but store url in auth service so that user is redirected after login)
      this.authService.setRedirectUrl(state.url);
      this.router.navigate(['/auth']);
    }

    return isAuthenticated;
  }

}
