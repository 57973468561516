import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DynamicForm, DynamicFormField, DynamicFormFieldType, DynamicFormFieldValidator, DynamicFormFieldValidatorType, DynamicFormSelectOption } from 'src/app/types/dynamic-form.model';
import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {
  
  @Input('submitButtonText') submitButtonText: string = 'Submit';
  @Input('submitButtonClass') submitButtonClass: string = 'pvm-button pvm-button-primary';
  @Input('hasCancelButton') hasCancelButton: boolean = false;
  @Input('cancelButtonText') cancelButtonText: string = 'Cancel';
  @Input('cancelButtonClass') cancelButtonClass: string = 'pvm-button pvm-button-secondary';
  @Input('cancelButtonIcon') cancelButtonIcon: string | undefined;

  private _disabled: boolean = false;
  get disabled() {
    return this._disabled;
  }
  @Input('disabled') set disabled(disabled: boolean) {
    this._disabled = disabled;
    
    if (disabled) {
      this.generatedForm?.disable();
    } else {
      console.log('REached enable form')
      this.generatedForm?.enable();
    }
  }

  @Output('cancel') cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('submit') submit: EventEmitter<any> = new EventEmitter<any>();

  private _form: DynamicForm | undefined;
  get form() {
    return this._form;
  }
  @Input('form') set form(form: DynamicForm | undefined) {
    this._form = form;
    this.sortFormFields(form)
    this.onFormChanged(form);
  }

  selectedLanguage: string | undefined;
  generatedForm: FormGroup | undefined;

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    // get currently selected language
    this.selectedLanguage = this.translateService.currentLang;
    // listen to changes to selected language
    this.translateService.onLangChange.subscribe(
      selectedLanguage => {
        this.selectedLanguage = selectedLanguage.lang;
      }
    );

    console.log(this.disabled)
    console.log(this.generatedForm)
  }

  private sortFormFields(form: DynamicForm | undefined) {
    if (form == null) return;
    // assuming that questionID should never be null 
    return form.formFields.sort((a, b) => a.sortIndex != null && b.sortIndex != null ? a?.sortIndex! - b?.sortIndex! : a?.questionID! - b?.questionID!)
  }

  private parseValidators(validators: DynamicFormFieldValidator[] | null | undefined) {
    if (validators == null) {
      return [];
    }

    const angularValidators: ValidatorFn[] = [];

    for (const validator of validators) {
      switch (validator.validatorType) {
        case DynamicFormFieldValidatorType.Required:
          angularValidators.push(Validators.required);
          break;
      
        case DynamicFormFieldValidatorType.RequiredTrue:
          angularValidators.push(Validators.requiredTrue);
          break;
      
        case DynamicFormFieldValidatorType.Min:
          angularValidators.push(Validators.min(validator.validatorArgument));
          break;
      
        case DynamicFormFieldValidatorType.Max:
          angularValidators.push(Validators.max(validator.validatorArgument));
          break;
      
        case DynamicFormFieldValidatorType.MinLength:
          angularValidators.push(Validators.minLength(validator.validatorArgument));
          break;
      
        case DynamicFormFieldValidatorType.MaxLength:
          angularValidators.push(Validators.maxLength(validator.validatorArgument));
          break;
      
        case DynamicFormFieldValidatorType.Email:
          angularValidators.push(Validators.email);
          break;
      
        case DynamicFormFieldValidatorType.Pattern:
          angularValidators.push(Validators.pattern(validator.validatorArgument));
          break;
      
        default:
          break;
      }
    }

    return angularValidators;
  }

  private parseInitialValue(initialValue: string, fieldType: DynamicFormFieldType) {
    if (initialValue == null) {
      return initialValue;
    }

    switch (fieldType) {
      case DynamicFormFieldType.Text:
      case DynamicFormFieldType.MultilineText:
        return initialValue;

      case DynamicFormFieldType.Number:
        try {
          return Number.parseFloat(initialValue);
        } catch (err: any) {
          return initialValue;
        }

      case DynamicFormFieldType.Date:
        try {
          const parsedDate = new Date(initialValue);
          return `${`0000${parsedDate.getFullYear()}`.slice(-4)}-${`00${parsedDate.getMonth() + 1}`.slice(-2)}-${`00${parsedDate.getDate()}`.slice(-2)}`;
        } catch (err: any) {
          return initialValue;
        }

      case DynamicFormFieldType.Select:
        return initialValue;

      case DynamicFormFieldType.Toggle:
      case DynamicFormFieldType.CheckBox:
      case DynamicFormFieldType.CheckBoxSelect:
      case DynamicFormFieldType.RadioButtonSelect:
        if (typeof initialValue === 'boolean') {
          return initialValue;
        }

        if (!['true', 'false', '0', '1'].includes(initialValue?.toLowerCase())) {
          return initialValue;
        }
        return 'true' === initialValue?.toLowerCase() || '1' === initialValue;
    
      default:
        return initialValue;
    }
  }

  onFormChanged(form: DynamicForm | undefined) {
    // reset form group
    this.generatedForm = undefined;

    if (form == null) {
      return;
    }

    // generate form group
    const generatedForm = new FormGroup({});
    const formFields = form.formFields;
    
    for (const formField of formFields) {
      const formControl: FormGroup | FormControl =
        formField.fieldType === DynamicFormFieldType.CheckBoxSelect ?
          new FormGroup(formField.selectOptions?.reduce(
            (acc: { [formControlName: string]: FormControl }, selectOption: DynamicFormSelectOption) => {
              // try to parse initial value for sub options
              let initialValue = formField.initialValue;
              try {
                // parse object
                initialValue = JSON.parse(initialValue);
                // get required key
                initialValue = initialValue[selectOption.value as keyof object];
              } catch (error: any) {
                // best effort parsing
              }

              acc[selectOption.value] = new FormControl(this.parseInitialValue(initialValue, formField.fieldType), {
                validators: this.parseValidators(selectOption.optionValidators)
              });
              return acc;
            }, {}
          ) || {}) :
          new FormControl(this.parseInitialValue(formField.initialValue, formField.fieldType), {
            validators: this.parseValidators(formField.fieldValidators)
          });

      generatedForm.addControl(formField.fieldName, formControl);
    }

    this.generatedForm = generatedForm;

    if (this.disabled) {
      this.generatedForm.disable();
    }

    setTimeout(() => {
      generatedForm.updateValueAndValidity();
    });
  }

  allSubControlsDirty(formField: DynamicFormField) {
    return formField.selectOptions?.reduce(
      (acc: boolean, selectOption: DynamicFormSelectOption) =>
        acc && this.generatedForm?.get(formField.fieldName)?.get(selectOption.value)?.dirty || false
      , true) || false;
  }

  onCancelButtonClick() {
    this.cancel.next(true);
  }

  onFormSubmit() {
    if (this.generatedForm == null) {
      return;
    }

    this.submit.emit(this.generatedForm?.value);
  }

}
