import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";


@Directive({
    selector: '[phoneMaskDirective]'
})
export class PhoneMaskDirective {

    constructor(public ngControl: NgControl) { }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event: any) {
        const input = event as HTMLInputElement;
        let trimmedInput = input.toString().replace(/\s+/g, '')

        const regex = /[+][0-9]+/g
        let newInput = trimmedInput.match(regex) == null && trimmedInput.length > 1 ? `+${trimmedInput}` : trimmedInput
        this.ngControl?.valueAccessor?.writeValue(newInput);
    }
}