import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { AlertType } from '../alert-type.enum';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() type: AlertType | undefined;
  @Input() message: string | undefined;
  @Input() dismissedAfter: number | undefined | null;
  @Input() alertId: number | undefined;
  @Output() alertClosed = new EventEmitter<boolean>();

  @ViewChild('btnClose') btnCloseRef: ElementRef | undefined;

  constructor() { }

  ngOnInit(): void {
    if (this.dismissedAfter && this.dismissedAfter > 0) {
      setTimeout( () => {
        this.btnCloseRef?.nativeElement?.click();
      }, this.dismissedAfter);
    }
  }

  close() {
    setTimeout( () => {
      this.alertClosed.emit(true);
    }, 150); // .15s is the default bootstrap fade time
  }

}
