interface NavigationItem {
    route: string[];
    labelDE: string;
    labelEN: string;
    matIconName?: string;
    iconIsFirst?: boolean;
}


const CustomerNavigation: NavigationItem[] = [
    {
        route: ['/home'],
        labelDE: 'Home',
        labelEN: 'Home',
        matIconName: 'home',
        iconIsFirst: true
    },
    {
        route: ['/profile'],
        labelDE: 'Profil',
        labelEN: 'Profile',
        matIconName: 'manage_accounts',
        iconIsFirst: false
    }
];

const CustomerAdminNavigation: NavigationItem[] = [
    {
        route: ['/home'],
        labelDE: 'Home',
        labelEN: 'Home',
        matIconName: 'home'
    },
    {
        route: ['/profile'],
        labelDE: 'Profil',
        labelEN: 'Profile',
        matIconName: undefined
    }
];

const AdminNavigation: NavigationItem[] = [
    {
        route: ['/admin'],
        labelDE: 'Admin',
        labelEN: 'Admin',
        matIconName: undefined
    },
    {
        route: ['/profile'],
        labelDE: 'Profil',
        labelEN: 'Profile',
        matIconName: undefined
    }
];


export {
    NavigationItem,
    CustomerNavigation,
    CustomerAdminNavigation,
    AdminNavigation
}