import { Injectable, Injector } from '@angular/core';
import { HomeService } from 'src/app/home/home.service';
import { ProfileService } from 'src/app/profile/profile.service';

import { AlertService } from './../alerts/alert.service';

@Injectable({
  providedIn: 'root'
})
export class DataCleanupService {

  constructor(
    private injector: Injector
  ) { }

  cleanupAllServices() {
    this.injector.get(AlertService)?.cleanup();
    this.injector.get(HomeService)?.cleanup();
    this.injector.get(ProfileService)?.cleanup();
  }
}
