import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthLoggedInGuard } from './auth/guards/auth-logged-in.guard';
import { AuthGuard } from './auth/guards/auth.guard';
import { RoleGuard } from './auth/guards/roles/role.guard';
import { UserRole } from './types/enums.model';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), canActivate: [AuthLoggedInGuard] },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard, RoleGuard], data: { permittedRoles: [ UserRole.Customer, UserRole.CustomerAdmin ] } },
  { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard] },
  { path: 'mbd', loadChildren: () => import('./my-blood-drop/my-blood-drop.module').then(m => m.MyBloodDropModule), data: { isMyBloodDrop: true }}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
