import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {MatTableModule, MatTable} from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertsComponent } from './alerts/alerts.component';
import { AlertComponent } from './alerts/alert/alert.component';
import { MatSelectModule } from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { CalenderIconComponent } from './components/calender-icon/calender-icon.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { LoadingAnimationComponent } from './components/loading-animation/loading-animation.component';
import { YesNoDialogComponent } from './components/yes-no-dialog/yes-no-dialog.component';
import { ConfirmDialogComponent } from '../shared/components/confirm-dialog/confirm-dialog.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AlertsComponent,
    AlertComponent,
    CalenderIconComponent,
    DynamicFormComponent,
    LoadingAnimationComponent,
    YesNoDialogComponent,
    ConfirmDialogComponent,
    PhoneMaskDirective
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,

    MatTableModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatBottomSheetModule,
    MatTooltipModule,
    MatDialogModule,
    MatMenuModule,
    MatExpansionModule,
    NgxMaterialTimepickerModule
  ],
  exports: [
    MatTableModule,
    TranslateModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    AlertsComponent,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatBottomSheetModule,
    MatTooltipModule,
    AlertsComponent,
    CalenderIconComponent,
    DynamicFormComponent,
    LoadingAnimationComponent,
    YesNoDialogComponent,
    MatDialogModule,
    MatMenuModule,
    MatExpansionModule,
    NgxMaterialTimepickerModule,
    PhoneMaskDirective
  ]
})
export class SharedModule {

}
