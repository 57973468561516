import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private isMyBloodDrop: boolean | undefined;

  private isMyBloodDropListener = new Subject<boolean>();

  getIsMyBloodDropListener() {
    return this.isMyBloodDropListener.asObservable();
  }

  getIsMyBloodDrop() {
    return this.isMyBloodDrop;
  }

  constructor() { }

  getTheme() {
    this.isMyBloodDrop = environment.isMyBloodDrop;
    this.isMyBloodDropListener.next(this.isMyBloodDrop)
  }

  setTheme(isMyBloodDrop: boolean) {
    this.isMyBloodDropListener.next(isMyBloodDrop)
    this.isMyBloodDrop = isMyBloodDrop;
  }

  getOrLoadTheme() {
    if (this.isMyBloodDrop) {
      this.isMyBloodDropListener.next(this.isMyBloodDrop)
    } else {
      this.getTheme()
    }
  }
}
