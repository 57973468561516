<!-- Previmo Logo -->
<!-- <div
  class="logo-container"
  [ngClass]="{ 'is-authenticated': isAuthenticated }">
  <img src="assets/svg/previmo-logo.svg">
</div>

<app-alerts></app-alerts>

<div
  *ngIf="isAuthenticated"
  class="header-container">
  <button
        mat-stroked-button
        color="warn"
        aria-label="more"
        (click)="onLogout()">
        {{ 'app.logoutButtonText' | translate }}
    </button>
</div>

<div
  class="language-selection-container"
  [ngClass]="{
    'expanded': languageSelectionExpanded,
    'is-authenticated': isAuthenticated
  }">
  <div
    class="d-flex justify-content-between"
    (click)="toggleExpanded()">
    <span
      class="fi"
      [ngClass]="{
        'fi-de': selectedLanguage === 'de',
        'fi-gb': selectedLanguage === 'en'
      }"></span>

    <img
      class="dropdown-arrow"
      [ngClass]="{ 'expanded': languageSelectionExpanded }"
      src="assets/svg/arrow-head-left.svg">
  </div>

  <div
    class="dropdown-container"
    [ngClass]="{ 'expanded': languageSelectionExpanded }">
    <hr>
    <div (click)="setLanguage('de')">
      <span class="fi fi-de"></span>
      <span class="ms-2">Deutsch</span>
    </div>
    <div class="mt-2" (click)="setLanguage('en')">
      <span class="fi fi-gb"></span>
      <span class="ms-2">English</span>
    </div>
  </div>
</div> -->

<app-header></app-header>
<app-alerts></app-alerts>

<div
  class="main-content-container"
  [ngClass]="{
    'main-content-padding': isAuthenticated,
    'is-authenticated': isAuthenticated
  }">
  <router-outlet></router-outlet>
</div>

<div
  class="navigation-container"
  [ngClass]="{ 'd-none': !isAuthenticated }">
  <ul [class]="'grid-columns-' + navigationItems.length">
    <li
      *ngFor="let navigationItem of navigationItems; index as i"
      [class]="'grid-row-1 grid-col-' + (i + 1)"
      [ngClass]="{ 'flex-row-reverse': navigationItem.iconIsFirst === false }"
      [routerLink]="navigationItem.route">
      <mat-icon *ngIf="navigationItem.matIconName != null">{{ navigationItem.matIconName }}</mat-icon>
      <span *ngIf="selectedLanguage !== 'en'">{{ navigationItem.labelDE }}</span>
      <span *ngIf="selectedLanguage === 'en'">{{ navigationItem.labelEN }}</span>
    </li>

    <li [class]="'active-grid-cell translate-x-' + (activeNavigationIndex * 100)"></li>
  </ul>
</div>
