interface Profile {
  profileID: any;
  profileNameDE: string;
  profileNameEN: string;
  profileDescriptionDE: string;
  profileDescriptionEN: string;
  groupID: string;
  analyticsProfileID: string;
  requiredProfile: any;
  profileFormQuestions?: any;
}

import { DynamicFormField } from "./dynamic-form.model";
import { Language, UserGender } from "./enums.model";

interface EditProfileData {
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirth: Date;
    gender: UserGender;
    phoneNumber: string;
    defaultLanguage: Language;
    changePassword: boolean;
    password?: string;
    repeatPassword?: string;
    currentPassword?: string;
}

enum EditProfileFailedReason {
    EmailAlreadyTaken = 'email-already-taken',
    PasswordsDontMatch = 'passwords-dont-match',
    Other = 'other'
}


interface ProfileAnamnesisQuestion extends DynamicFormField {
    questionID: number;
    answer: string | { [key: string]: any };
}


interface ProfileFaqQuestion {
    questionID: number;
    questionName: string;
    questionDE: string;
    questionEN: string;
    answerDE: string;
    answerEN: string;
}


export {
    Profile,
    EditProfileData,
    EditProfileFailedReason,
    ProfileAnamnesisQuestion,
    ProfileFaqQuestion
}
