<div class="d-flex justify-content-between align-items-center mb-4">
    <span
        mat-dialog-title
        class="m-0">
        <span *ngIf="data?.title != null">{{ data?.title }}</span>
        <span *ngIf="data?.i18nTitleKey != null">{{ data.i18nTitleKey | translate }}</span>
    </span>

    <button
        mat-icon-button
        aria-label="close"
        (click)="closeDialog('cancel')">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div *ngIf="data?.messageLines != null">
        <div *ngFor="let message of data?.messageLines">
            <span [innerHTML]="message"></span>
        </div>
    </div>

    <div *ngIf="data?.i18nMessageKeys != null">
        <div *ngFor="let message of data?.i18nMessageKeys">
            <span>{{ message | translate }}</span>
        </div>
    </div>

    <div
        *ngIf="data?.commentAvailable != null && data?.commentAvailable"
        class="pvm-form-field w-100 mt-4">

        <textarea
            class="pvm-input"
            id="comment"
            aria-labelledby="commentLabel"
            aria-describedby="commentError"
            [(ngModel)]="comment"></textarea>
    
        <div
            class="pvm-input-label pvm-input-label-active"
            id="commentLabel">
            <span>
                <span *ngIf="data?.commentTitle != null">{{ data?.commentTitle }}</span>
                <span *ngIf="data?.i18nCommentTitleKey != null">{{ data?.i18nCommentTitleKey! | translate }}</span>
                <span *ngIf="data?.commentTitle == null && data?.i18nCommentTitleKey == null">{{ 'app.shared.yesNoDialog.commentTitle' | translate }}</span>
            </span>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <button
        mat-stroked-button
        class="pvm-button pvm-button-primary"
        (click)="closeDialog('yes')"
        [disabled]="data?.commentAvailable && data?.commentMandatory && (comment == null || comment.trim().length === 0)">
        <span *ngIf="data?.yesText != null">{{ data?.yesText }}</span>
        <span *ngIf="data?.i18nYesKey != null">{{ data.i18nYesKey! | translate }}</span>
        <span *ngIf="data?.yesText == null && data?.i18nYesKey == null">{{ 'app.shared.yesNoDialog.yesButtonText' | translate }}</span>
    </button>

    <button
        mat-stroked-button
        class="pvm-button pvm-button-secondary ms-2"
        (click)="closeDialog('no')">
        <span *ngIf="data?.noText != null">{{ data?.noText }}</span>
        <span *ngIf="data?.i18nNoKey != null">{{ data.i18nNoKey! | translate }}</span>
        <span *ngIf="data?.noText == null && data?.i18nNoKey == null">{{ 'app.shared.yesNoDialog.noButtonText' | translate }}</span>
    </button>
</mat-dialog-actions>
