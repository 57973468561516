import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/shared/auth.service';
import { AlertType } from '../shared/alerts/alert-type.enum';
import { AlertService } from '../shared/alerts/alert.service';
// import { instant } from '../shared/shared.module';
import { Language, UserGender, UserRole } from '../types/enums.model';
import { EditProfileData, ProfileAnamnesisQuestion, EditProfileFailedReason, ProfileFaqQuestion } from '../types/profile.model';


const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private anamnesisQuestions: ProfileAnamnesisQuestion[] | undefined;
  private faqQuestions: ProfileFaqQuestion[] | undefined;

  private profileSavedListener = new Subject<boolean | EditProfileFailedReason>();
  private anamnesisQuestionsListener = new Subject<ProfileAnamnesisQuestion[]>();
  private faqQuestionsListener = new Subject<ProfileFaqQuestion[]>();


  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService,
    private authService: AuthService,
    private translateService: TranslateService
  ) { }

  getProfileSavedListener() {
    return this.profileSavedListener.asObservable();
  }

  getAnamnesisDataListener() {
    return this.anamnesisQuestionsListener.asObservable();
  }

  getFaqQuestionsListener() {
    return this.faqQuestionsListener.asObservable();
  }


  /**
   * PROFILE
   */

  editProfile(editProfileData: EditProfileData) {
    const requestBody: any = {...editProfileData};
    if (!editProfileData.changePassword) {
      delete requestBody.password;
      delete requestBody.repeatPassword;
    }
    delete requestBody.changePassword;

    this.httpClient.put<{
      token: string,
      exp: number,
      userID: string,
      role: UserRole,
      firstName: string,
      lastName: string,
      email: string,
      dateOfBirth: Date,
      gender: UserGender,
      phoneNumber: string,
      title: string,
      defaultLanguage: Language
    }>(BACKEND_URL + '/profile', requestBody)
    .subscribe(
      authData => {
        // store new token data in local storage
        // convert expiration unix epoch time to JS date
        const expirationDate = new Date(authData.exp * 1000);

        this.authService.authenticateUser(
          authData.token,
          expirationDate,
          authData.userID,
          authData.role,
          authData.firstName,
          authData.lastName,
          authData.email,
          authData.dateOfBirth,
          authData.gender,
          authData.phoneNumber,
          authData.defaultLanguage
        );

        // show success notification
        this.alertService.createAlert(
          AlertType.Success,
          this.translateService.instant('app.profile.service.profileEditSuccessful')
        );
        this.profileSavedListener.next(true);
      },
      error => {
        // check the error type
        let failedReason: EditProfileFailedReason = EditProfileFailedReason.Other;

        if (error?.status === 409 && error?.error?.errorType === 'EmailAlreadyTaken') {
          failedReason = EditProfileFailedReason.EmailAlreadyTaken;
        } else if (error?.status === 400 && error?.error?.errorType === 'PasswordConfirmationMismatch') {
          failedReason = EditProfileFailedReason.PasswordsDontMatch;
        }

        if (failedReason === EditProfileFailedReason.Other) {
          this.alertService.createAlert(
            AlertType.Danger,
            this.translateService.instant('app.profile.service.profileEditFailed')
          );
        }
        
        this.profileSavedListener.next(failedReason);
      }
    );
  }


  /**
   * ANAMNESIS
   */

  getAnamnesisData() {
    this.httpClient.get<{
      anamnesisQuestions: ProfileAnamnesisQuestion[]
    }>(BACKEND_URL + '/profile/anamnesis')
    .subscribe(
      result => {
        this.anamnesisQuestions = result.anamnesisQuestions;

        this.anamnesisQuestionsListener.next([ ...this.anamnesisQuestions ]);
      }, error => {
        this.alertService.createAlert(
          AlertType.Danger,
          this.translateService.instant('app.profile.service.loadingAnamnesisFailed'),
          null
        );
        this.anamnesisQuestionsListener.next([]);
      }
    );
  }

  setAnamnesisAnswers(anamnesisAnswers: ProfileAnamnesisQuestion[]) {
    this.httpClient.put(
      BACKEND_URL + '/profile/anamnesis',
      { anamnesisAnswers: anamnesisAnswers.map(anamnesisAnswers => { return { questionID: anamnesisAnswers.questionID, answer: anamnesisAnswers.answer } }) },
      { responseType: 'text' }
    )
    .subscribe(
      result => {
        this.anamnesisQuestions = anamnesisAnswers;

        // show success notification
        this.alertService.createAlert(
          AlertType.Success,
          this.translateService.instant('app.profile.service.savingAnamnesisSuccessful')
        );
        // notify subscribers about successful save
        this.anamnesisQuestionsListener.next([ ...this.anamnesisQuestions ]);
      }, error => {
        this.alertService.createAlert(
          AlertType.Danger,
          this.translateService.instant('app.profile.service.savingAnamnesisFailed'),
          null
        );
        this.anamnesisQuestionsListener.next([]);
      }
    );
  }


  /**
   * FAQ
   */

   getFaqQuestions() {
    this.httpClient.get<{
      faqQuestions: ProfileFaqQuestion[]
    }>(BACKEND_URL + '/profile/faq')
    .subscribe(
      result => {
        this.faqQuestions = result.faqQuestions;

        this.faqQuestionsListener.next([ ...this.faqQuestions ]);
      }, error => {
        this.alertService.createAlert(
          AlertType.Danger,
          this.translateService.instant('app.profile.service.loadingAnamnesisFailed'),
          null
        );
        this.anamnesisQuestionsListener.next([]);
      }
    );
  }


  /*
   * Helper Functions
   */

  cleanup() {
    this.anamnesisQuestions = undefined;
    this.faqQuestions = undefined;
  }

}
