<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div mat-dialog-actions>
  <button class="pvm-button pvm-button-secondary m-3" (click)="onDismiss()">{{ "app.admin.cancelActionDialog" | translate }}</button>
  <button class="pvm-button pvm-button-primary" (click)="onConfirm()">{{ "app.admin.confirmActionDialog" | translate }}</button>
</div>
