enum Language {
  German = 'de',
  English = 'en'
}

enum UserGender {
  Male = 'm',
  Female = 'f'
}

enum UserRole {
  Customer = 'customer',
  CustomerAdmin = 'customer-admin',
  Admin = 'admin'
}

enum AppointmentStatus {
  Upcoming = 'upcoming',
  InProgress = 'in-progress',
  Completed = 'completed',
  Cancelled = 'cancelled'
}

enum BookingStep {
  Welcome = 0,
  Profiles = 1,
  Anamnesis = 2,
  Slots = 3,
  Confirmation = 4,
  BookingSuccessful = 5,
  BookingFailed = 6,
  InvalidEngagement = 7
}

enum MbdBookingStep {
  Welcome = 0,
  Slots = 1,
  Anamnesis = 2,
  Auth = 3,
  Success = 4
}

enum BookingInvalidEngagementReason {
  EngagementDoesntExist = 'engagement-doesnt-exist',
  EngagementBookingClosed = 'engagement-booking-closed',
  EngagementFullyBooked = 'engagement-fully-booked',
  AppointmentAlreadyExists = 'appointment-already-exists',
  Other = 'other'
}

enum BookingFailedReason {
  SlotAlreadyFull = 'slot-already-full',
  Other = 'other'
}

enum AppointmentAction {
  EditSlot = 'edit-slot',
  EditProfiles = 'edit-profiles',
  CancelAppointment = 'cancel-appointment'
}

enum EditSlotFailedReason {
  AppointmentDoesntExist = 'appointment-doesnt-exist',
  SlotAlreadyFull = 'slot-already-full',
  Other = 'other'
}

enum EditProfilesFailedReason {


  Other = 'other'
}

enum CancelAppointmentFailedReason {
  AppointmentNotCancellable = 'appointment-not-cancellable',
  Other = 'other'
}


export {
  Language,
  UserGender,
  UserRole,
  AppointmentStatus,
  BookingStep,
  MbdBookingStep,
  BookingInvalidEngagementReason,
  BookingFailedReason,
  AppointmentAction,
  EditSlotFailedReason,
  EditProfilesFailedReason,
  CancelAppointmentFailedReason
}
